@font-face {
  font-family: 'ProductSans';
  src: url(./ProductSans.ttf) format("truetype")
}
@font-face {
  font-family: 'NotoSans';
  src: url(./NotoSans.otf) format("opentype")
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    background-color: #ffffff !important;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.MuiContainer-root {
  min-height: 100vh;
  background-color: #f9f9f9 !important;
}